import LinksBar from "../LinksBar/LinksBar";
import { ArrowDownCircleIcon } from "@heroicons/react/24/solid";
import ScrollIntoView from 'react-scroll-into-view';

function Home() {
  return (
    <section>
      <div className="bg-primary-3 grid h-[calc(100dvh)] place-content-center justify-items-center">
        <p className="text-3xl font-bold text-white md:text-5xl">
          Hi, I am Youen
        </p>
        <p className="text-3xl font-bold text-white md:text-5xl">
          Software Engineer
        </p>
        <hr className="bg-secondary-2 my-4 h-0.5 w-2/3 border-0" />
        <LinksBar />
      </div>
      <div className="grid place-content-center justify-items-center">
        <ScrollIntoView selector="#about">
          <ArrowDownCircleIcon className="text-secondary-2 -mt-10 h-8 w-8 animate-bounce hover:cursor-pointer" />
        </ScrollIntoView>
      </div>
    </section>
  );
}

export default Home;
