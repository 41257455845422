import SectionTitle from "../Common/SectionTitle";
import ExperienceData from "../../Data/ExperienceData";
import ExperienceTile from "../Common/ExperienceTile";

import logoSerma from "./img/serma_logo.jpg";
import logoSt from "./img/st_logo.png";
import logoAutmix from "./img/autmix_logo.jpg"

function Work() {
  const workItems: Array<ExperienceData> = [
    {
      logo: logoSerma,
      title: "SERMA Safety & Cybersecurity",
      startDate: new Date("10/01/2022"),
      location: "Bordeaux (France)",
      subtitle: "Cybersecurity Software Engineer",
      description: "In charge of developing and updating the tools used by the software team. Also in charge of evaluating the security of sensitive products with fuzzing, static analysis and internal tools.",
      tags: ["SaaS", "Python", "TypeScript", "Security review"]
    },
    {
      logo: logoSt,
      title: "STMicroelectronics",
      startDate: new Date("09/01/2019"),
      endDate: new Date("09/30/2022"),
      location: "Rennes (France)",
      subtitle: "Software Engineer",
      description: "Block release training. Tests development, based on cryptography and biometry for secure elements validation. Contributed to innovative products awarded by the \"CES 2022 Inovation Award\".",
      tags: ["Python", "JavaCard", "C", "Tests", "Validation"]
    },
    {
      logo: logoAutmix,
      title: "Autmix",
      startDate: new Date("07/01/2021"),
      endDate: new Date("09/30/2021"),
      location: "Querétaro (Mexico)",
      subtitle: "R&D Developer",
      description: "Joined the local team to automate the manufacturing execution system (MES) reporting process from server database.",
      tags: ["JavaScript", "SQL Server", "HTML"]
    }
  ];

  return (
    <section id="about" className="bg-primary-1 p-4 md:py-16">
    <div className="mx-4 md:mx-auto md:w-2/3">
      <SectionTitle title="Work" />
      <div className="flex flex-col md:mt-8">
        {
          workItems.map((workItem, key) => (
            <ExperienceTile key={key} data={workItem} />
          ))
        }
      </div>
    </div>
    </section>
  );
}

export default Work;
