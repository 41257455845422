import ExperienceData from "../../Data/ExperienceData";
import ExperienceTile from "../Common/ExperienceTile";
import SectionTitle from "../Common/SectionTitle";

import logoInsa from "./img/insa_logo.jpg";

function Education() {
  const educationItems: Array<ExperienceData> = [
    {
      logo: logoInsa,
      title: "INSA",
      startDate: new Date("07/01/2017"),
      endDate: new Date("07/01/2022"),
      location: "Rennes (France)",
      subtitle: "Software and Embedded Systems Engineer",
      description: "Advanced notions in programming, computing, electronics, transmission and management.",
      tags: []
    }
  ]
  return (
    <section id="about" className="bg-white p-4 md:py-16">
    <div className="mx-4 md:mx-auto md:w-2/3">
      <SectionTitle title="Education" />
      <div className="flex flex-col md:mt-8">
        {
          educationItems.map((educationItem, key) => (
            <ExperienceTile key={key} data={educationItem} />
          ))
        }
      </div>
    </div>
    </section>
  );
}

export default Education;
