import LinksBar from "../LinksBar/LinksBar";

function Footer() {
  const email = "contact@youen.dev";

  return (
    <section className="flex flex-col items-center bg-primary-3 py-4">
      <p className="mb-2 text-sm text-white md:text-base">
        {email}
      </p>
      <LinksBar />
    </section>
  );
}

export default Footer;
