import { differenceInMonths } from "date-fns";
import ExperienceData from "../../Data/ExperienceData";
import Tag from "./Tag";

// get duration between two dates in string format:
function getDurationString(startDate: Date, endDate: Date) {
  endDate.setDate(endDate.getDate() +1); // select the day after 'endDate' to compute duration (allows to consider full months of 30 days)
  let durationMonths: number = differenceInMonths(endDate, startDate);
  if(durationMonths < 12) {
    return `${durationMonths} month${durationMonths > 1 ? 's' : ''}`;
  } else {
    const durationYears = Math.floor(durationMonths/12);
    durationMonths = durationMonths % 12;
    let returnText = '';
    if (durationYears) {
      returnText += `${durationYears} year${durationYears > 1 ? 's' : ''}`;
    }
    if (durationMonths) {
      if (returnText !== '') returnText += ' ';  // add space
      returnText += `${durationMonths} month${durationMonths > 1 ? 's' : ''}`;
    }
    return returnText;
  }
}

// get pretty string to display dates and duration
function getPrettyStringDates(startDate: Date, endDate: Date | "Now") {
  let returnText = '';
  returnText += startDate.toLocaleDateString('en-US', {month: 'short', year: 'numeric'});
  returnText += ' - ';
  if (endDate instanceof Date) {
    returnText += endDate.toLocaleDateString('en-US', {month: 'short', year: 'numeric'});
  } else {  // i.e. 'endDate' is 'Now'
    returnText += endDate;
    endDate = new Date();
  }
  returnText += ' • ';
  returnText += getDurationString(startDate, endDate);
  return returnText;
}

function ExperienceTile({ data }: { data: ExperienceData }) {
  return (
    <div className="flex flex-col my-8">
      <div className="flex flex-row">
        <div className="w-16 h-16">
          <img className="rounded" src={data.logo} alt="logo" />
        </div>
        <div className="flex flex-col ml-3 md:ml-6 -mt-2">
          <p className="text-neutral-3 font-semibold text-lg">{data.title}</p>
          <p className="text-neutral-2 text-sm md:text-base">{data.endDate ? getPrettyStringDates(data.startDate, data.endDate) : getPrettyStringDates(data.startDate, "Now")}</p>
          <p className="text-neutral-2 text-sm md:text-base">{data.location}</p>
        </div>
      </div>
      <div className="flex flex-col mt-4 md:ml-88px">
        <p className="text-neutral-3 font-semibold text-lg">{data.subtitle}</p>
        <p className="text-neutral-3 text-justify">{data.description}</p>
        {
          data.tags.length ? (
            <div className="flex flex-row mt-2 flex-wrap">
              {
                data.tags.map((tag, key) => (
                  <Tag key={key} text={tag} />
                ))
              }
            </div>
          ) : (<></>)  // no element if no data.tags
        }
      </div>
    </div>
  );
}

export default ExperienceTile;
