import { useState } from "react";
import ProjectData from "../../Data/ProjectData";
import SectionTitle from "../Common/SectionTitle";
import Tag from "../Common/Tag";
import { LinkIcon } from "@heroicons/react/24/outline";

import imgSourcesHugoDecrypte from "./img/sources_hugo_decrypte.svg";
import imgEssenceFr from "./img/essence.png";
import imgPrivateGPT from "./img/privategpt.svg";

function ProjectTile({ data }: { data: ProjectData }) {
  const imageBgColorDefault: string = "#D1DBD4"; // default color is gray/green

  const [hovered, setHovered] = useState(false);

  return (
    <div
      className="group/tile mt-8 flex flex-col rounded-2xl bg-white text-neutral-3 shadow-[0_10px_25px_0px_rgba(0,0,0,0.3)] hover:scale-105 md:mx-6 md:mt-12"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <div
        className="relative flex h-36 justify-center overflow-hidden rounded-t-2xl"
        style={{
          backgroundColor:
            data.imageBgColor && hovered
              ? data.imageBgColor
              : imageBgColorDefault,
        }}
      >
        <img
          className="contrast-120 brightness-120 object-scale-down grayscale filter group-hover/tile:filter-none"
          src={data.image}
          alt="project view"
          style={{ scale: data.imageScale ? `${data.imageScale}%` : "100%" }}
        />
        <div className="absolute h-full w-full bg-primary-2/20 group-hover/tile:hidden"></div>
      </div>
      <div className="flex flex-col rounded-b-2xl bg-white p-4 last:grow">
        <p className="mb-2 text-lg font-semibold">{data.title}</p>
        <p className="mb-2 text-justify text-sm">{data.description}</p>
        <div className="mt-auto">
          {data.link ? (
            <a
              className="group/link mb-4 flex max-w-fit flex-row items-center"
              href={data.link}
              rel="external nofollow"
              title="Open project"
            >
              <LinkIcon className="mr-1 h-4 w-4 group-hover/link:stroke-2" />
              <p className="text-sm group-hover/link:font-semibold">
                See project
              </p>
            </a>
          ) : (
            <></> // no element if no data.link
          )}
          {data.tags.length ? (
            <div className="mt-2 flex flex-row flex-wrap">
              {data.tags.map((tag, key) => (
                <Tag key={key} text={tag} />
              ))}
            </div>
          ) : (
            <></> // no element if no data.tags
          )}
        </div>
      </div>
    </div>
  );
}

function Projects() {
  const projectItems: Array<ProjectData> = [
    {
      image: imgPrivateGPT,
      imageBgColor: "#C9B9FE",
      title: "Custom PrivateGPT",
      description:
        "Custom use and training of PrivateGPT in an isolated environment. Optimization of GPU resources for documents querying.",
      tags: ["AI", "LLaMA", "Docker"],
    },
    {
      image: imgEssenceFr,
      imageScale: 90,
      title: "Essence FR",
      description:
        "Design and implementation of a web server that computes statistics in real time and predicts the prices evolution for every gas station in France.",
      tags: ["NodeJS", "Express", "Jest"],
      link: "https://github.com/essencefr/essencefr-backend",
    },
    {
      image: imgSourcesHugoDecrypte,
      imageScale: 80,
      title: "Sources Hugo Décrypte",
      description:
        "Web service that showcases the sources used by a journalist. Front-end and back-end implementation, automation and server database administration.",
      tags: ["React", "Tailwind CSS", "PostgreSQL", "AWS"],
      link: "https://sourceshugodecrypte.netlify.app/",
    },
  ];

  return (
    <section id="about" className="bg-primary-1 p-4 md:py-16">
      <div className="mx-4 md:mx-auto md:w-2/3">
        <SectionTitle title="Projects" />
        <div className="mb-8 grid lg:grid-cols-2">
          {projectItems.map((projectItem, key) => (
            <ProjectTile key={key} data={projectItem} />
          ))}
        </div>
      </div>
    </section>
  );
}

export default Projects;
