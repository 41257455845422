import { useState } from "react";
import { CheckIcon, DocumentDuplicateIcon } from "@heroicons/react/24/outline";

// Special div that copies a value into the clipboard when clicked + displays a message for the user
function CopyButton({ valueToCopy }: { valueToCopy: string }) {
  const [isCopied, setIsCopied] = useState(false);

  const onCopyText = (text: string) => {
    navigator.clipboard.writeText(text);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1300);
  };

  return (
    <div className="text-neutral-2 hover:cursor-default">
      <DocumentDuplicateIcon
        className="ml-2 h-4 hover:cursor-pointer"
        style={{ display: isCopied ? "none" : "block" }}
        onClick={() => onCopyText(valueToCopy)}
        title="Copy"
      />
      <div className="relative flex items-center">
        <CheckIcon
          className="ml-2 h-4"
          style={{ display: isCopied ? "block" : "none" }}
        />
        <p
          className="absolute left-6 text-sm"
          style={{ display: isCopied ? "block" : "none" }}
        >
          Copied
        </p>
      </div>
    </div>
  );
}

export default CopyButton;
