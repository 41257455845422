import SectionTitle from "../Common/SectionTitle";
import { EnvelopeIcon, MapPinIcon } from "@heroicons/react/24/outline";
import CopyButton from "../Common/CopyButton";

function About() {
  const picProfile = "https://avatars.githubusercontent.com/u/75084658";
  const email = "contact@youen.dev";

  return (
    <section id="about" className="bg-primary-1 p-4 md:py-16">
      <div className="mx-4 md:mx-auto md:w-2/3">
        <SectionTitle title="About me" />
        <div className="my-8 flex flex-col-reverse items-center md:flex-row md:justify-center">
          <div className="flex flex-col items-center text-neutral-3 md:items-start">
            <p className="mt-6 max-w-sm text-justify md:mt-0">
              Jack-of-all-trades, creative and inventive, I am fully involved in
              all the projects I realize, on my own or in a group. Open-minded,
              I thirst after knowledges and discoveries. I adapt myself easily
              to others and to any change. I am easy to get on with and I enjoy
              discussions, debates and sharing.
            </p>
            <div className="mt-4 flex flex-col">
              <div className="flex items-center">
                <a href={`mailto:${email}`} rel="external nofollow" className="flex items-center hover:cursor-pointer hover:underline" title="Send email">
                  <EnvelopeIcon className="mr-1 h-5" />
                  <p>{email}</p>
                </a>
                <CopyButton valueToCopy={email} />
              </div>
              <div className="flex items-center">
                <MapPinIcon className="mr-1 h-5" />
                <p>France</p>
              </div>
            </div>
          </div>
          <img
            className="w-52 rounded-full md:mx-8 md:w-60 lg:mx-16"
            alt="Profile Pic"
            src={picProfile}
          />
        </div>
      </div>
    </section>
  );
}

export default About;
