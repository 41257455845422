import SingleSkillData from "../../Data/SingleSkillData";
import CategorySkillData from "../../Data/CatagorySkillData";
import SectionTitle from "../Common/SectionTitle";

function ProgressBar({ percentage }: { percentage: number }) {
  return (
    <div className="bg-neutral-1 h-3 w-full rounded-full">
      <div
        className="bg-secondary-2 h-3 rounded-full"
        style={{ width: `${percentage}%` }}
      ></div>
    </div>
  );
}

function SingleSkillBlock({ data }: { data: SingleSkillData; }) {
  return (
    <div className="hover:scale-105">
      <p className="mb-1 mt-1">{data.name}</p>
      <ProgressBar percentage={data.percentage} />
    </div>
  );
}

function CategorySkillBlock({ data }: { data: CategorySkillData; }) {  
  return (
    <div className="mt-8 md:mx-6">
      <p className="mb-1 font-semibold text-center">{data.title}</p>
      {
        data.skills.map((skill, key) => (
          <SingleSkillBlock key={key} data={skill}/>
        ))
      }
    </div>
  );
}

function Skills() {
  const skills: Array<CategorySkillData> = [
    {
      title: "Programming languages",
      skills: [
        {name: "Python", percentage: 92},
        {name: "JavaScript", percentage: 52},
        {name: "TypeScript", percentage: 47},
        {name: "C", percentage: 32},
      ]
    },
    {
      title: "Back-end",
      skills: [
        {name: "NodeJS", percentage: 45},
        {name: "Express", percentage: 37},
        {name: "Django", percentage: 26},
      ]
    },
    {
      title: "Front-end",
      skills: [
        {name: "ReactJS", percentage: 45},
        {name: "HTML", percentage: 40},
        {name: "CSS", percentage: 38},
      ]
    },
    {
      title: "Database",
      skills: [
        {name: "MongoDB", percentage: 62},
        {name: "PostgreSQL", percentage: 48},
        {name: "MySQL", percentage: 43},
      ]
    },
    {
      title: "Tools",
      skills: [
        {name: "Jest", percentage: 75},
        {name: "Docker", percentage: 56},
        {name: "Netlify", percentage: 45},
        {name: "Heroku", percentage: 32},
      ]
    },
    {
      title: "Spoken languages",
      skills: [
        {name: "French", percentage: 100},
        {name: "English", percentage: 85},
        {name: "Spanish", percentage: 65},
      ]
    }
  ];

  return (
    <section className="bg-white p-4 md:py-16">
      <div className="mx-4 md:mx-auto md:w-2/3">
        <SectionTitle title="Skills" />
        <div className="grid md:grid-cols-2 xl:grid-cols-3 mb-8">
          {
            skills.map((category, key) => (
              <CategorySkillBlock key={key} data={category} />
            ))
          }
        </div>
      </div>
    </section>
  );
}

export default Skills;
